import { AxiosInstance, AxiosResponse } from 'axios';
import queryString from 'query-string';

import * as JWT from '@breathelife/jwt';
import {
  Language,
  LeadMarketingMetadata,
  RecommendedCoverageServiceResponse,
  VersionedAnswers,
} from '@breathelife/types';

import { validateTokenNonce } from '../helpers/jwt';
import Urls from './urls';

type AxiosResponseWithToken<T = Record<string, any>> = AxiosResponse<{ token: string } & T>;

export class ConsumerGateway {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    // NOTE: This object is created in the `Gateway` constructor, any modifications will also apply in that class.
    // (if we want distinct interceptors some refactoring will be needed)
    this.instance = instance;
  }

  public async updateApplication<T>(applicationId: string, language: Language, data: any): Promise<AxiosResponse<T>> {
    return this.instance.patch<T>(`${Urls.consumer.applications}/${applicationId}?lang=${language}`, data);
  }

  public async createApplication(data: any): Promise<AxiosResponse> {
    const url = Urls.consumer.applications;
    const nonce = JWT.generateNonce();

    const result: AxiosResponseWithToken = await this.instance.post(`${url}?nonce=${nonce}`, data);
    validateTokenNonce(result.data?.token, nonce);
    return result;
  }

  public async fetchApplication(applicationId: string): Promise<AxiosResponse> {
    const url = Urls.consumer.applications;
    return this.instance.get(`${url}/${applicationId}`);
  }

  public async getApplicationByToken(appToken: string): Promise<AxiosResponseWithToken> {
    const url = Urls.consumer.applications;
    const nonce = JWT.generateNonce();
    const requestUrl = `${url}?token=${appToken}&nonce=${nonce}`;

    const result = await this.instance.get(requestUrl);
    validateTokenNonce(result.data?.token, nonce);
    return result;
  }

  public async fetchQuestion(applicationId: string, questionId: string, language: Language): Promise<AxiosResponse> {
    const url = Urls.shared.questions;
    return this.instance.get(`${url}/${questionId}?appId=${applicationId}&lang=${language}`);
  }

  public async getPreviousQuestionData(
    applicationId: string,
    currentQuestionId: string,
    language: Language,
  ): Promise<AxiosResponse> {
    const url = Urls.shared.questions;
    return this.instance.get(`${url}/${currentQuestionId}?appId=${applicationId}&previous=true&lang=${language}`);
  }

  public async sendQuestionAnswer(
    applicationId: string,
    questionId: string,
    versionedAnswers: VersionedAnswers,
    language: Language,
  ): Promise<AxiosResponse> {
    const url = Urls.shared.questions;
    return this.instance.put(`${url}/${questionId}?appId=${applicationId}&lang=${language}`, {
      answers: versionedAnswers.v1,
      answersV2: versionedAnswers.v2,
    });
  }

  public async getRecommendedCoverage(
    applicationId: string,
  ): Promise<AxiosResponse<RecommendedCoverageServiceResponse>> {
    return this.instance.get(`${Urls.shared.recommendedCoverage}/${applicationId}`);
  }

  public async fetchSummary(
    applicationId: string,
    options?: { sectionId?: string; lang?: string },
  ): Promise<AxiosResponse> {
    const query = options ? queryString.stringify(options) : {};
    const url = `${Urls.shared.summary}/${applicationId}?${query}`;

    return this.instance.get(url);
  }

  public async updateLanguage(applicationId: string, lang: string): Promise<AxiosResponse> {
    return this.instance.patch(`${Urls.shared.applications}/${applicationId}`, { lang });
  }

  public async getQuestionnaireLandingStepId(applicationId: string): Promise<AxiosResponse> {
    return this.instance.get(`${Urls.shared.questionnaireLandingStep}/${applicationId}`);
  }

  public async getQuestionnaireLandingStep(language: Language): Promise<AxiosResponse> {
    return this.instance.get(`${Urls.shared.questionnaireLandingStep}?lang=${language}`);
  }

  public async postQuestionnaireLandingStep(
    versionedAnswers: VersionedAnswers,
    stepId: string,
    lang: Language,
    marketingMetadata?: LeadMarketingMetadata,
  ): Promise<AxiosResponse> {
    const url = Urls.shared.questionnaireLandingStep;
    const nonce = JWT.generateNonce();

    const result: AxiosResponseWithToken = await this.instance.post(`${url}?nonce=${nonce}`, {
      answers: versionedAnswers.v1,
      answersV2: versionedAnswers.v2,
      stepId,
      lang,
      marketingMetadata,
    });
    validateTokenNonce(result.data?.token, nonce);
    return result;
  }

  public async createCloverCharge(
    appId: string,
    source: string,
    amountInCents: number,
  ): Promise<AxiosResponse<{ paymentStatus: string; transactionId: string }>> {
    return this.instance.post(`${Urls.shared.paymentClover}`, {
      appId,
      source,
      amount: amountInCents,
    });
  }
}
