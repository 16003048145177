import { AxiosError, InternalAxiosRequestConfig } from 'axios';

/**
 * Interceptors to be added to an axios service object.
 * For intercepts handling errors, be sure to return a Promise.reject
 * or else the error gets silenced, effectively skipping all other "error" interceptors
 * and returning 'undefined' to the API caller (silencing the error)
 */

export type Redirects = {
  401?: string;
};

export const addBasicAuthorizationIntoHeaderInterceptor =
  (callbackKey?: string) =>
  (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    if (callbackKey && config.headers) {
      config.headers['Authorization'] = `Basic ${callbackKey}`;
    }

    return config;
  };

export const addJwtIntoHeaderInterceptor = (token?: string) => (config: InternalAxiosRequestConfig) => {
  if (token && config.headers) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  return config;
};

export const addProductIntoHeaderInterceptor = (product: string) => (config: InternalAxiosRequestConfig) => {
  if (config.headers) {
    config.headers['BL-Product'] = product;
  }
  return config;
};

export const addTraceIntoHeaderInterceptor = (traceId?: string) => (config: InternalAxiosRequestConfig) => {
  if (traceId && config.headers) {
    config.headers['bl-trace-id'] = traceId;
  }

  return config;
};

export const addTimezoneIntoHeaderInterceptor = () => (config: InternalAxiosRequestConfig) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (config.headers) {
    config.headers['bl-timezone'] = timezone || 'America/Toronto';
  }
  return config;
};

// ⚠️ If you update these header keys, also update the same keys in the core backend
export const RELEASE_VERSION_HTTP_HEADER_KEY = 'bl-release-version';
export const RELOAD_REQUIRED_HTTP_HEADER_KEY = 'bl-reload-required';
/** Enforces the release version is set in request headers and that the page reloads if signaled by a server response. */
export const enforceReleaseVersion = (error: AxiosError): Promise<AxiosError> => {
  if (error.response?.headers?.[RELOAD_REQUIRED_HTTP_HEADER_KEY] === 'true') {
    window.location.reload();
  }

  return Promise.reject(error);
};

export const addReleaseVersionHeader = (releaseVersion: string) => (config: InternalAxiosRequestConfig) => {
  if (config.headers) {
    config.headers[RELEASE_VERSION_HTTP_HEADER_KEY] = releaseVersion;
  }
  return config;
};

export const addErrorRedirectInterceptor =
  (redirects: Redirects) => (error: AxiosError<{ code: number; name: string }>) => {
    const errorData = error?.response?.data;
    const isNotAuthenticatedThrown = errorData?.code === 401 && errorData?.name === 'NotAuthenticated';
    const isAuthenticationError = isNotAuthenticatedThrown || error.response?.status == 401;
    const currentPath = window.location.pathname;

    if (isAuthenticationError && redirects[401] && currentPath !== redirects[401]) {
      window.location.href = redirects[401];
    }
    return Promise.reject(error);
  };
